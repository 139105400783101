<template>
  <div class="ranges">
    <ul v-if="ranges">
      <li
        v-for="(value, key) in ranges"
        @click="$emit('clickRange', { range: value, shortcut: key })"
        :data-range-key="key"
        :key="key"
        class="p-3 d-flex align-items-start rounded"
        :class="range_class(value)"
      >
        <template v-if="key.includes('CUSTOM')">
          <span>{{labelCustom(key)}}</span>
          <b-button class="ml-auto" size="sm" variant="link" @click.stop="$emit('edit', key)">
              <fluency-icon type="edit"></fluency-icon>
          </b-button>
        </template>
        <template v-else>
          {{$filters.capitalize(key)}}
        </template>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    ranges: Object,
    selected: Object
  },
  data () {
    return {
      compareOptions: [
        { text: 'Previous Span', value: 'Previous Span' }
      ]
    }
  },
  methods: {
    range_class (range) {
      const dateRangeObj = range.dateRange || range
      return { active: this.selected.startDate && this.$moment(this.selected.startDate).isSame(dateRangeObj[0], 'date') && this.selected.endDate && this.$moment(this.selected.endDate).isSame(dateRangeObj[1], 'date') }
    },
    labelCustom (value) {
      const [nothing, days, offset] = value.split(':')
      let name = `Last ${days} Days`
      if (offset > 0) {
        name += `, Offset ${offset} Days`
      }
      return name
    }
  }
}
</script>
